.CircularProgressbarPath {
  stroke: red;
}

.CircularProgressbarTrail {
  stroke: gray;
}

.CircularProgressbarText {
  fill: yellow;
}

.CircularProgressbarBackground {
  fill: green;
}

.progressbarContainer {
  width: 200px;
  height: 642px;
}

.recordTime {
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  color: #101010;
  margin: 28px 0 20px 0;
}

.progressbarWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.voiceRecorder {
  width: 132px;
  height: 70px;
}

.progressText {
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  color: #101010;
  margin: 59px 0 52px 0;
}

.modify {
  margin-bottom: 69px;
}

.additionalLoader {
  width: 164px;
  height: 164px;
  animation: rotation 3s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.seconds {
  margin: 27px 0 20px 0;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
}

.pulseContainer {
  position: relative;
  overflow: hidden;
}

.pulseWrapper {
  position: relative;
  left: 0;
  top: 0;
}

.empty,
.filled {
  width: 130px;
  height: 70px;
}

.empty {
  fill: #bc0000;
}

.wrapperFilled {
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  overflow: hidden;
  transition: width 0.6s linear;
}

.faceContainer {
  position: relative;
  width: 146px;
  height: 146px;
  background-color: #e5e5e5;
  border-radius: 10px;
}

.face {
  position: relative;
  width: 146px;
  height: 146px;
  z-index: 2;
  border-radius: 10px;
  background: no-repeat bottom url('../../../../assets/full.svg');
  animation: filling 25s ease-in-out infinite;
  background-position-y: 100px;
  background-size: 200px 80%;
}

@keyframes filling {
  25% {
    background-size: 500px 100%;
    background-position-y: 75px;
  }
  50% {
    background-size: 500px 120%;
    background-position-y: 20px;
  }

  75% {
    background-size: 500px 185%;
    background-position-y: -50px;
  }
  100% {
    background-size: 500px 250%;
    background-position-y: -130px;
  }
}

.soundWrapper {
  width: max-content;
  margin: 45px auto 36px auto;
  height: 60px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.animationContainer {
  width: 100%;
  height: 15px;
}

.soundContainer {
  //width: 150px;
  height: 100%;
  display: inline-block;
  //margin-left: 4px;
}

.animationContainer .soundContainer > div {
  position: relative;
  height: 100%;
  width: 3px;
  margin: 0 4px;
  border-radius: 100px;
  background: #FE9400;
  display: inline-block;
  animation: wave 3s infinite ease-in-out;
  //-webkit-animation: wave 3s infinite ease-in-out;
}

.animationContainer .soundContainer .rect1 {
  animation-delay: 0.1s;
  -webkit-animation-delay: 0.1s;
}

.animationContainer .soundContainer .rect2 {
  animation-delay: 0.1s;
  -webkit-animation-delay: 0.1s;
}

.animationContainer .soundContainer .rect3 {
  animation-delay: 0.2s;
  -webkit-animation-delay: 0.2s;
}

.animationContainer .soundContainer .rect4 {
  animation-delay: 0.3s;
  -webkit-animation-delay: 0.3s;
}

.animationContainer .soundContainer .rect5 {
  animation-delay: 0.4s;
  -webkit-animation-delay: 0.4s;
}

.animationContainer .soundContainer .rect6 {
  animation-delay: 0.5s;
  -webkit-animation-delay: 0.5s;
}

@keyframes wave {
  0%, 100% {
    transform: scaleY(1);
    background: #FE9400;
  }
  16.67% {
    transform: scaleY(3);
    background: #FE9400;
  }
  33.33% {
    transform: scaleY(1);
    background: #FE9400;
  }
  50% {
    transform: scaleY(3);
    background: #FE9400;
  }
  66.67% {
    transform: scaleY(1);
    background: #FE9400;
  }
  83.34% {
    transform: scaleY(3);
    background: #FE9400;
  }
}
