.mainWrapper {
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.mainInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  color: #353535;
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}

.logoTitle {
  color: #353535;
  font-family: "Merriweather Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  margin: 4px 0;
}

.titleUser {
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  color: #101010;
}

.titleCompany {
  color: #707070;
  font-family: "Merriweather Sans", sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: normal;
  text-transform: capitalize;
}

.companyWrapper {
  width: max-content;
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 4px 0 60px 0;
}

.company {
  display: flex;
}

.logo {
  width: 243px;
  height: 51px;
}

.description {
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0;
}
