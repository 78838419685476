.resultWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  //background-color: #bc0000;
  width: 100%;
  height: 100%;
  padding-bottom: 48px;
  justify-content: center;
}

.speechIdentify {
  font-weight: 400;
  font-size: 20px;
  color: #FFFFFF;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 4px;
}

.isSuccess {
  background-color: #18830f;
  color: #72bb79;
}

.isEnroll {
  background-color: #ffffff;
  color: #72bb79;
}

.descriptionText {
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
  margin-top: 22px;
}

.statusIcon {
  margin-top: -25px;
}

.isSuccessIcon {
  margin-left: 20px;
}

.isEnrollIcon {
  width: 98px;
  height: 123px;
}

.statusText {
  font-family: "Maven Pro", sans-serif;
  max-width: 368px;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
}

.isEnrollText{
  font-size: 24px;
  color: #101010;
}

.homeScreenBtn {
  width: 250px;
  margin-top: 50px;
}

.speechTitle {
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-weight: 700;
}
